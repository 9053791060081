import React, {useEffect, useState} from "react"
import "./Checkout.css"
import banner from "../../assets/images/cover.jpg"
import Subtotal from "../Subtotal/Subtotal"
import CheckoutProduct from '../CheckoutProduct/CheckoutProduct'
import { useStateVale } from '../../Provider/StateProvider'

function Checkout() {
    const [{basket, user}, dispatch] = useStateVale();
    useEffect(() => { 
        
        if (user !=null)
        {
            console.log('user:'+user)
        }
        console.log(basket)
        
      }, [])
    return (
        <div className="checkout">
            <div className="checkout__left">
                <img className="checkout__ad" src={banner} alt=""/>
                <div>
                {!user ? <h3>Hello, Guest</h3> : user.map(item =>(
                       <h3>Hello, {item.email}</h3>
                    ))}
                    
                    <h2 className="checkout__title">Your Shopping Basket</h2>
                    {basket.map(item =>(
                        <CheckoutProduct
                            id={item.id}
                            title={item.title}
                            image={item.image}
                            price={item.price}
                            rating={item.rating}
                        />
                    ))}
                </div>
            </div>
            <div className="checkout__right">
                <Subtotal/>
            </div>
        </div>
    )
}

export default Checkout