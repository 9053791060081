import React, { useState } from 'react'
import "./Login.css"
import logo from "../../assets/images/BBnew.jpeg"
import { Link, useHistory, useNavigate } from 'react-router-dom'
//import { auth } from '../../firebase';
import { useStateVale } from '../../Provider/StateProvider'

function Login() {
    let navigate = useNavigate(); 
    
    //const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [{user}, dispatch] = useStateVale();
    const signIn = e => { 
        console.log('signin')
        e.preventDefault();
             let formdata = new FormData();

            formdata.append('email', email)
            formdata.append('password', password)
            fetch(`https://bnb.jnzsoftware.co.za/api/login`, {
                    method:"POST",
                    headers:{
                        'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                    }, 
                    body:formdata
                
                
                }).then((putresponse)=> putresponse.json())
                .then((putresponse) => {
                    console.log(putresponse)

                    if(putresponse.status == false ){
                        alert(' User not found')
                    }
                    else{
                        dispatch({
                            type: "LOGIN",
                            user:{
                                email: email,
                                token: putresponse.token,
                                id: putresponse.useerid,
                                
                            }
                        })
                        navigate('/')
                    }
                //      dispatch({
                //type: 'ADD_TO_BASKET',
                // item: {
                    
                //     title: title,
                //     image: image,
                //     price: price,
                //     rating: rating,
                // },
            //});
                   // navigate('/')
                    
                
                //console.log('updated users')
                
                })
                .catch((error) => {
                console.error(error);
                });
        // auth
        //     .signInWithEmailAndPassword(email, password)
        //     .then((auth) => {
        //         history.push('/')
        //     })
        //     .catch(error => alert(error.message))
    }
    const register = e => { 
        console.log('register')
        navigate("/signup");
        // e.preventDefault();

        // auth
        //     .createUserWithEmailAndPassword(email, password)
        //     .then((auth) =>{
        //         console.log(auth);
        //         if (auth){
        //             history.push("/")
        //         }
        //     })
        //     .catch(error => alert(error.message))
    }

    return (
        <div className="login">
            <Link to="/">
                <img className="login__logo" src={logo} alt=""/>
            </Link>
            <h1>Sign In</h1>
            <div className="login__container">
                
                <form>
                    <h5>E-mail</h5>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>

                    <h5>Password</h5>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>

                    <button type="submit" onClick={signIn} className="login__signInButton">Sign In</button>
                </form>

                <p>
                    By signing-in you agree to Biltong and Budz Conditions of Use & Sale. Please see our Privacy Notice, our Cookies Notice and our Interest-Based Ads
                </p>

                <button onClick={register} className="login__registerButton">Sign Up</button>
            </div>
            
        </div>
    )
}

export default Login