import React, {useEffect, useState} from "react"
import "./Home.css";
import homepic from "../../assets/images/cover.jpg";
import Product from "../Product/Product";
import Grid from '@mui/material/Grid';
 
import watch from "../../assets/images/1.png"
import iwatch from "../../assets/images/2.png"
import phone from "../../assets/images/3.png"
import roll from "../../assets/images/4.png"
import roller from "../../assets/images/5.png"
import bottle from "../../assets/images/bulkseed.jpeg"
import nike from "../../assets/images/bulkseed.jpeg"
function Home() {
    const [shopItems, setShopItems] = useState([]);
    const [shopItemsTwo, setShopItemsTwo] = useState([]);
    const [shopItemsThree, setShopItemsThree] = useState([]);
    const [shopItemsFour, setShopItemsFour] = useState([]);
     return (
        <div className="home">
            <div className="home__container">
                <img className="home__image" src={roll} alt=""/>
                <div>
                    <img className="home__image_other" src={watch} alt=""/>
                    <img className="home__image_other_new" src={iwatch} alt=""/>
                    <img className="home__image_other_new" src={phone} alt=""/>
                    <img className="home__image_other_new" src={roll} alt=""/>
                    <img className="home__image_other_new" src={roller} alt=""/>
                </div>
                
                {/* <div  >
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                {shopItemsFour.filter((item)=>{
                                    if(item.image1 === ''){
                                        return null
                                    }
                                    else{
                                        return item
                                    }
                                }).map((item, i)=>{
                                    return(
                                        
                                            <Product 
                                                title={item.name}
                                                price={item.price*2}
                                                image={item.image1}
                                            />
                                    
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                {shopItemsThree.filter((item)=>{
                                    if(item.image1 === ''){
                                        return null
                                    }
                                    else{
                                        return item
                                    }
                                }).map((item, i)=>{
                                    return(
                                        
                                            <Product 
                                                title={item.name}
                                                price={item.price*2}
                                                image={item.image1}
                                            />
                                    
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                {shopItemsTwo.filter((item)=>{
                                    if(item.image1 === ''){
                                        return null
                                    }
                                    else{
                                        return item
                                    }
                                }).map((item, i)=>{
                                    return(
                                        
                                            <Product 
                                                title={item.name}
                                                price={item.price*2}
                                                image={item.image1}
                                            />
                                    
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                {shopItems.filter((item)=>{
                                    if(item.image1 === ''){
                                        return null
                                    }
                                    else{
                                        return item
                                    }
                                }).map((item, i)=>{
                                    return(
                                        
                                            <Product 
                                                title={item.name}
                                                price={item.price*2}
                                                image={item.image1}
                                            />
                                    
                                    )
                                })}
                            </Grid>
                        </Grid>
                       
                  
                  
                    
                </div> */}
                {/* <div className="home__row">
                    <Product 
                        title="iWatch" 
                        price={139.99}
                        image={iwatch}
                        rating={5}/>
                    <Product
                        title="iPhone" 
                        price={599.99}
                        image={phone}
                        rating={4}/>
                   
                </div>
                <div className="home__row">
                    <Product 
                        title="Punk rolls" 
                        price={59.99}
                        image={roll}
                        rating={1}/>
                    <Product
                        title="Water bottle" 
                        price={29.99}
                        image={bottle}
                        rating={3}/>
                    <Product 
                        title="Watch" 
                        price={79.99}
                        image={watch}
                        rating={2}/>
                   
                </div>
                <div className="home__row">
                    <Product
                        title="Nike Running shoes" 
                        price={359.99}
                        image={nike}
                        rating={4}/>
                    <Product 
                        title="Punk rolls" 
                        price={59.99}
                        image={roll}
                        rating={1}/>
                    <Product
                        title="Water bottle" 
                        price={29.99}
                        image={bottle}
                        rating={3}/>
                    <Product 
                        title="Watch" 
                        price={79.99}
                        image={watch}
                        rating={2}/>
                   
                </div> */}
            </div>
        </div>
    )
}

export default Home