import React, { useState, useEffect } from 'react'
import "./Signup.css"
import logo from "../../assets/images/BBnew.jpeg"
import { Link, useHistory,useNavigate } from 'react-router-dom'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



function Signup() {
    const axios = require('axios').default;
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [telephoneTwo, setTelephoneTwo] = useState('');
    const [company, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [websiteOption, setWebsiteOption] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    //const [addressFinal, setAddressFinal] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('');
    const [postcode, setPostcode] = useState('');
    const [vatNo, setVatNo] = useState('');
    const [doSell, setDoSell] = useState('');
    const [doSellOption, setDoSellOption] = useState('');
    const [comments, setComments] = useState('');
    

    let navigate = useNavigate(); 
    const register = e => { 
            e.preventDefault();
        console.log('register')
        console.log(firstName)


        if(firstName === ''){
            alert('Please your First Name')
         
        }
        else if(lastName === ''){
          alert('Please your Last Name')

        }
        else if(email === ''){
            alert('Please your e-mail')
        }
 
        else if(telephone === ''){
            alert('Please your Telephone Number')
        }
        else if(company === ''){
            alert('Please your Company name')
        }
        else if(website === ''){
             alert('Please your Company website')
        }
        else if(websiteOption === ''){
            alert('Please select if you want to be listed on our site')
        }
        else if(address === ''){
            alert('Please your Address')
        }
        else if(city === ''){
            alert('Please your City')
        }
        else if(province === ''){
            alert('Please your Province / Region')
        }
        else if(country === ''){
            alert('Please select your Counrty')
        }
        else if(postcode === ''){
            alert('Please your Postal code')
        }
        else if(vatNo === ''){
            alert('Please your ID/Passport number')
        }
       
       
        else{
            console.log('passed' )
            
            const addressFinal = address+', '+addressTwo
            console.log(addressFinal)
            let formdata = new FormData();

            formdata.append('company', company)
            formdata.append('phonenumber', telephone)
            formdata.append('website', website)
            // formdata.append('vat', vatNo)
            formdata.append('active', '0')
            
            //Address
            formdata.append('address', addressFinal)
            formdata.append('city', city)
            formdata.append('state', province)
            formdata.append('zip', postcode)
            formdata.append('country', country)
            ////Billing
            formdata.append('billing_street', addressFinal)
            formdata.append('billing_city', city)
            formdata.append('billing_state', province)
            formdata.append('billing_zip', postcode)
            formdata.append('billing_country', country)
            //Country
            formdata.append('shipping_street', addressFinal)
            formdata.append('shipping_city', city)
            formdata.append('shipping_state', province)
            formdata.append('shipping_zip', postcode)
            formdata.append('shipping_country', country)
            //Custom Fields
            formdata.append('custom_fields[customers][1]',websiteOption)
            formdata.append('custom_fields[customers][3]',telephoneTwo)
            formdata.append('custom_fields[customers][4]',doSell)
            formdata.append('custom_fields[customers][5]',doSellOption)
            formdata.append('custom_fields[customers][6]', comments)
            formdata.append('custom_fields[customers]7]', vatNo)
            console.log('company: '+ company)
            let data ={
                company: company,
            }

                  fetch('https://bnb.jnzsoftware.co.za/api/customers', {
                            mode:'cors',
                            method:"POST",
                            headers:{

                                'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',

                                
                                
                            }, 
                            
                             body:formdata
                             //body: JSON.stringify(formdata)
                        }).then(createUser =>createUser.json())
                        .then(createUserJson =>{
                            console.log(createUserJson)
                            console.log('User created')
                            if(createUserJson.message ==='Client add successful.'){
                                let contactdata = new FormData();

                                contactdata.append('customer_id', createUserJson.data)
                                contactdata.append('phonenumber', telephone)
                                contactdata.append('custom_fields[contacts][2]', telephoneTwo)
                                contactdata.append('firstname', firstName)
                                contactdata.append('lastname', lastName)
                                contactdata.append('email', email)
                                contactdata.append('password', password)
                               
                                fetch('https://bnb.jnzsoftware.co.za/api/contacts/', {
                                    mode:'cors',
                                    method:"POST",
                                    headers:{

                                        'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',

                                        
                                        
                                    }, 
                                    
                                    body:contactdata
                                    //body: JSON.stringify(formdata)
                                }).then(createUser =>createUser.json())
                                .then(createUserJson =>{
                                    console.log(createUserJson)
                                    console.log('Contact created')
                                  
                
                                })
                                .catch((error) => {
                                    console.error(error);
                                    
                                });
                            }
                            else{
                                alert('Failed to create customer')
                            }
        
                        })
                        .catch((error) => {
                            console.error(error);
                            
                          });
         }
  
    }

    const signIn = e => { 
        console.log('signin')
        navigate("/login");
    
    }

  return (
    <div className="signup">
        <Link to="/">
            <img className="login__logo" src={logo} alt=""/>
        </Link>
        <h1>Sign Up</h1>
        <div className="signup__container">
            
            <form>
                <h5>Contact First Name:*</h5>
                <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                <h5>Contact Last Name: *</h5>
                <input type="text" value={lastName} onChange={e => setLastName(e.target.value)}/>
                <h5>Contact E-mail:*</h5>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
                <h5>User Password</h5>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                <h5>Contact Telephone: *</h5>
                <input type="text" value={telephone} onChange={e => setTelephone(e.target.value)}/>
                <h5>Contact Telephone 2:</h5>
                <input type="text" value={telephoneTwo} onChange={e => setTelephoneTwo(e.target.value)}/>
                <h5>Company Name:*</h5>
                <input type="text" value={company} onChange={e => setCompany(e.target.value)}/>
                <h5>Website Url:*</h5>
                <input type="text" value={website} onChange={e => setWebsite(e.target.value)}/>
                <h5>Would You Like To Be Listed On Our Website</h5>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={websiteOption}
                    label="Yes/No"
                    onChange={e => setWebsiteOption(e.target.value)}
                    style={{ width: '98%', height:40, }}
                    MenuProps={{
                        PaperProps: {
                        sx: {
                            bgcolor: '#01322c', color:'white',
                            '& .MuiMenuItem-root': {
                            padding: 2,
                            width: 200,
                            },
                        },
                        },
                    }}
                >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                </Select>

                <h5>Address:*</h5>
                <input type="text" value={address} onChange={e => setAddress(e.target.value)}/>
                <h5>Address Line 2: </h5>
                <input type="text" value={addressTwo} onChange={e => setAddressTwo(e.target.value)}/>
                <h5>City:*</h5>
                <input type="text" value={city} onChange={e => setCity(e.target.value)}/>
                <h5>Province/Region:*</h5>
                <input type="text" value={province} onChange={e => setProvince(e.target.value)}/>
                <h5>Country:*</h5>
                <input type="text" value={country} onChange={e => setCountry(e.target.value)}/>
                <h5>Postcode:*</h5>
                <input type="text" value={postcode} onChange={e => setPostcode(e.target.value)}/>
                <h5>ID/Passport Number</h5>
                <input type="text" value={vatNo} onChange={e => setVatNo(e.target.value)}/>
                <h5>Do you supply cannabis seeds already?:</h5>
                       <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={doSell}
                    label="Yes/No"
                    onChange={e => setDoSell(e.target.value)}
                    style={{ width: '98%', height:40, }}
                    MenuProps={{
                        PaperProps: {
                        sx: {
                            bgcolor: '#01322c', color:'white',
                            '& .MuiMenuItem-root': {
                            padding: 2,
                            width: 200,
                            },
                        },
                        },
                    }}
                >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                </Select>
                <h5>If Yes to above, who do you currently buy from</h5>
                <input type="text" value={doSellOption} onChange={e => setDoSellOption(e.target.value)}/>
                <h5>Comments</h5>
                <input type="text" value={comments} onChange={e => setComments(e.target.value)}/>

                <button type="submit" onClick={register} className="signup__registerButton">Sign Up</button>
                
            </form>

            <p>
                By signing-up you agree to Bilton and Budz Conditions of Use & Sale. 
            </p>
            <button  onClick={signIn} className="signup__signInButton">Sign In</button>
            
        </div>
    </div>
  )
}

export default Signup