import React, {useEffect, useState} from 'react';
import './Orders.css'
// import { db } from '../../firebase';
import { useStateVale } from '../../Provider/StateProvider';
import Order from '../Order/Order'
import { Link, useHistory, useNavigate } from 'react-router-dom'


function Orders() {

    const [{basket, user}, dispatch] = useStateVale();
    const [orders, setOrders] = useState([]);
     let navigate = useNavigate(); 
    const userid =user.map(item=>(item.id))


    // useEffect(() => {
    //     if(user){
    //         db
    //         .collection('users')
    //         .doc(user?.uid)
    //         .collection('orders')
    //         .orderBy('created', 'desc')
    //         .onSnapshot(snapshot =>(
    //             setOrders(snapshot.docs.map(doc =>({
    //                 id:doc.id,
    //                 data: doc.data()
    //             })))
    //         ))
    //     }
    //     else{
    //         setOrders([])
    //     }
       
    // }, [user])
    useEffect(() => { 
        
  
        try{
            fetch(`https://bnb.jnzsoftware.co.za/api/customers/${userid}`, {
                method:"GET",
                headers:{
                     'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                }, 
              
               
            }).then((putresponse)=> putresponse.json())
            .then((putresponse) => {
                console.log(putresponse.company)
                const company = putresponse.company
                fetch(`https://bnb.jnzsoftware.co.za/api/invoices/search/${company}`, {
                    method:"GET",
                    headers:{
                        'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                    }, 
                
                
                }).then((putresponsein)=> putresponsein.json())
                .then((putresponseinJson) => {
                    console.log('why')
                    console.log(putresponseinJson)
                    setOrders(putresponseinJson)
                    // const singleProduct = putresponse.filter((item)=>{
                    
                    //     if (item.name.toLowerCase().includes(shopName.toLowerCase())){
                    //         //return item
                    //         if (item.categories.toLowerCase().includes(name.toLowerCase())){
                    //             return item
                    //         }
                    //         else{
                    //             return null
                    //         }
                    //     }
                    //     else{
                    //         return null
                    //     }
                    // })
                    // var columnA = this.props.result.splice(0, halfwayPoint)
                    // var columnB = this.props.result.splice(halfwayPoint)
                    //setShopItems(singleProduct)
                //  setShouldShow(true)
                    //console.log(singleProduct)
                
                
                })
                .catch((error) => {
                console.error(error);
                });
                
                
              
            })
            .catch((error) => {
              console.error(error);
            });
            
          
            
        }
        catch (e){
            console.error(e);
        }

        
      }, [])
    return (
        <div className="orders">
            <h1> Your Orders</h1>
            <div className='orders__order'>
               {orders=='' ? 
                <h1>You have no past/current orders</h1>:
              
                orders?.map(order => (
                    // <Order order={order} />
                    <div>
                        <h1>{order.prefix}{order.id} </h1>
                    </div>
                ))
               }
            </div>
        </div>
    )
}

export default Orders