import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { StateProvider } from './Provider/StateProvider';
import reducer, { initialState } from './Reducer/reducer';



ReactDOM.render(
  <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </BrowserRouter>,
  // <React.StrictMode>
  //   <StateProvider initialState={initialState} reducer={reducer}>
  //     <App />
  //   </StateProvider>
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
