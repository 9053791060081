import React, {useEffect, useState} from "react"
import { Routes, Route, useParams, useLocation,useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Product from "../Product/Product";
import './BiltongAndBudzGrowshop.css'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import bnbimage from '../../assets/images/bnbgen.png'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
function BiltongAndBudzGrowshop() {
    const [shopItems, setShopItems] = useState([]);
    const [itemSearch, setItemSearch] = useState('');
    const [shouldShow, setShouldShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [open, setOpen] = useState(false);
    let navigate = useNavigate(); 
    const shopName ='Biltong And Budz Growshop'
    
      const handleAll = () => {
        setItemSearch('')
        
      };
      const handleAutoflower = () => {
        setItemSearch('Biltong And Budz Growshop Nutrients and Amendments')
      };
      const handleCBD = () => {
        setItemSearch('Budbags')
      };
      const handleCBG = () => {
        setItemSearch('Biltong And Budz Growshop Soil and Aggregates')
      };
      const handleFast = () => {
        setItemSearch('Fast Version')
      };
      const handleFem = () => {
        setItemSearch('Feminized')
      };
      const handleReg = () => {
        setItemSearch('Regular')
      };
      const handleOpen = (item) => {
        console.log(item.item.name)
        const prname = item.item.commodity_code
        setSelectedProductName(prname)
        console.log(prname)
        setSelectedProduct(item.item)
        setOpen(true)
      };
      const handleClose = () => setOpen(false);
  return (
    <div className="bnbgrowshop">
    <div className="bnbgrowshop__container">
    <text className="bnbgrowshop__maintitle">Biltong And Budz Grow Shop</text>
    <br/>
    <br/>
      <div className="eugen__row">
        {/* <Card sx={{ width: 150 }}>
            <CardActionArea onClick={()=>{navigate(`/eu-genetics/available`, {state:{name:'In Stock', image:bnbimage}})}}>
                <CardMedia
                    component='img'
                    height='140'
                    
                    image={bnbimage}
                    alt=''

                />
                <Typography align='center' gutterBottom variant="h5" component="div">
                    In Stock
                </Typography>
                
            </CardActionArea>
        </Card> */}
        <Card sx={{ width: 150,marginLeft:3, }}>
            <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-growshop/shop`, {state:{name:'Budbags'}})}}>
                <CardMedia
                    component='img'
                    height='140'
                    
                    image={bnbimage}
                    alt=''

                />
                <Typography align='center' gutterBottom variant="h5" component="div">
                  Budbags
                </Typography>
                
            </CardActionArea>
        </Card>
        <Card sx={{ width: 150,marginLeft:3,  }}>
            <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-growshop/shop`, {state:{name:'Nutrients and Liquid Amendment'}})}}>
                <CardMedia
                    component='img'
                    height='140'
                    image={bnbimage}
                    alt=''

                />
                <Typography align='center' gutterBottom variant="h5" component="div">
                  Nutrients and Liquid Amendment
                </Typography>
                
            </CardActionArea>
        </Card>
        <Card sx={{ width: 150,marginLeft:3,  }}>
            <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-growshop/shop`, {state:{name:'Soil and Aggregates'}})}}>
                <CardMedia
                    component='img'
                    height='140'
                    image={bnbimage}
                    alt=''

                />
                <Typography align='center' gutterBottom variant="h5" component="div">
                  Soil and Aggregates
                </Typography>
                
            </CardActionArea>
        </Card>

      </div>

    </div>
</div>
  )
}

export default BiltongAndBudzGrowshop