import React, {useEffect, useState} from "react"
import './Header.css';
import logo from '../../assets/images/BBnew.jpeg';
import SearchIcon from '@mui/icons-material/Search';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
// import { Link } from 'react-router-dom';
import { Link, useHistory, useNavigate } from 'react-router-dom'
import { useStateVale } from '../../Provider/StateProvider';
// import { auth } from '../../firebase';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


function Header() {

    let navigate = useNavigate(); 
    const [{basket, user}, dispatch] = useStateVale();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElConUs, setAnchorElConUs] = React.useState(null);
    const [anchorElCon, setAnchorElCon] = React.useState(null);
    const [anchorElBNBG, setAnchorElBNBG] = React.useState(null);
    const open = Boolean(anchorEl);
    const openCol = Boolean(anchorElCon);
    const openColUs = Boolean(anchorElConUs);
    const openBNBG = Boolean(anchorElBNBG);

    useEffect(() => { 
        
        console.log('user:'+user)
        
      }, [])
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClickUs = () => {
        navigate("/us-genetics");
      };
    const handleClickCon = (event) => {
        setAnchorElCon(event.currentTarget);
      };
      const handleClickBNBG = (event) => {
        setAnchorElBNBG(event.currentTarget);
      };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleCloseBnb = () => {
        setAnchorEl(null);
        setAnchorElCon(null);
      };
      const handleCloseUs = () => {
        setAnchorEl(null);
        setAnchorElConUs(null);
      };
      const handleCloseBnbG = () => {
        setAnchorEl(null);
        setAnchorElBNBG(null);
      };
    const handleAccount =(event) =>{
        console.log('my account')
    }

    const handleAuthentication = () => {
        console.log("hi")
        // navigate("/");
        if (user){
            
            dispatch({
                type:"LOGOUT",
            })
           
        }
    }
    const changePage = () => {
        navigate("/eu-genetics");
    }

    return (
        <div className='header'>
            <Link to="/">
                <img className="header__logo" src={logo} alt=""/>
            </Link>
            
            <div className="header__search">
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={()=>{navigate(`/biltong-and-budz-genetics`)}}
                >
                    Genetics
                </Button>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={()=>{navigate(`/biltong-and-budz-growshop`)}}
                >
                    Growshop
                </Button>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={()=>{navigate(`/biltong-and-budz-pre-packed`)}}
                >
                    Pre-packed
                </Button>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={changePage}
                >
                    EU Genetics
                </Button>
                {/* <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickUs}
                >
                    US Genetic
                </Button> */}
     
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={()=>{navigate(`/contact-us`)}}
                >
                    Contact Us
                </Button>
                {user ? 
                   <Button
                        id="basic-button"
                        
                        aria-haspopup="true"
                        
                        onClick={()=>{navigate(`/account`)}}
                    >
                        My Account
                    </Button>
                    : null
                }
            </div>

       
            <div className="header_nav">
                <Link to={ "/login"}>                           
                    <div onClick={handleAuthentication} className="header__option">
                        <span className="header_optionLineOne">Hello {!user ? 'Guest' : user.map(item=>(item.email))}</span>
                        <span className="header_optionLineTwo">{user ? 'Sign Out' : 'Sign In'}</span>
                    </div>
                </Link>
                {user ? 
                    <Link to="/orders">
                        <div className="header__option">
                            <span className="header_optionLineOne">Returns</span>
                            <span className="header_optionLineTwo">& Orders</span>
                        </div>
                    </Link>
                    : null
                }
             
        
                <Link to="/checkout">
                    <div className="header__optionBasket">
                        <ShoppingBasketIcon />
                        <span className="header_optionLineTwo header__basketCount">{basket?.length}</span>
                    </div>

                </Link>
                
            </div>
        </div>
    )
}

export default Header