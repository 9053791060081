import React, {useEffect, useState} from "react"
import Card from '@mui/material/Card';
import './USGen.css'
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import MassMedicalStrainsImage from '../../assets/images/US/MassMedicalStrains.jpeg'
import { Link, useHistory, useNavigate } from 'react-router-dom'
function USgen() {
    let navigate = useNavigate(); 

    const nextPage = () =>{
        navigate("/us-genetics/mass-medical-strains");
    }
  return (
    <div className="usgen">
        <div className="usgen__container">
            <text className="usgen__maintitle">US Genetics</text>

            <div className="usgen__row">
                <Card sx={{ width: 150 }}>
                    
                    <CardActionArea onClick={nextPage}>
                        <CardMedia
                            component='img'
                            height='140'
                            
                            image={MassMedicalStrainsImage}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Mass Medical Strains
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                

            
            </div>
            
            
        </div>
    </div>
  )
}

export default USgen