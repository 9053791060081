import React, {useEffect, useState} from "react"
import { Routes, Route, useParams, useLocation, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Product from "../Product/Product";
import './BiltongAndBudzGenetics.css'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import bnbimage from '../../assets/images/bnbgen.png'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function BiltongAndBudzGenetics() {
    const [shopItems, setShopItems] = useState([]);
    const [itemSearch, setItemSearch] = useState('');
    const [shouldShow, setShouldShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [open, setOpen] = useState(false);
    let navigate = useNavigate(); 
    const shopName ='Biltong And Budz Genetics'
    
      const handleAll = () => {
        setItemSearch('')
        
      };
      const handleAutoflower = () => {
        setItemSearch('Autoflower')
      };
      const handleCBD = () => {
        setItemSearch('CBD')
      };
      const handleCBG = () => {
        setItemSearch('CBG')
      };
      const handleFast = () => {
        setItemSearch('Fast Version')
      };
      const handleFem = () => {
        setItemSearch('Feminized')
      };
      const handleReg = () => {
        setItemSearch('Regular')
      };
      const handleOpen = (item) => {
        console.log(item.item.name)
        const prname = item.item.name.replace('Biltong And Budz Genetics -','').replace('- 100 Seeds','')
        setSelectedProductName(prname)
        setSelectedProduct(item.item)
        setOpen(true)
      };
      const handleClose = () => setOpen(false);
  return (
    <div className="bnbshop">
      <div className="bnbshop__container">
        <text className="bnbshop__maintitle">Biltong And Budz Genetics</text>
        <br/>
        <br/>
        <div className="eugen__row">
          {/* <Card sx={{ width: 150 }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/available`, {state:{name:'In Stock', image:bnbimage}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                      In Stock
                  </Typography>
                  
              </CardActionArea>
          </Card> */}
          <Card sx={{ width: 150,marginLeft:3, }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/shop`, {state:{name:'Feminized'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Feminized
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/shop`, {state:{name:'Autoflower'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Autoflower
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/shop`, {state:{name:'Regular'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Regular
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/shop`, {state:{name:'Fast Version'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Fast Version
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/categories/shop`, {state:{name:'CBD'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    CBD
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/biltong-and-budz-genetics/categories/shop`, {state:{name:'CBG'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    CBG
                  </Typography>
                  
              </CardActionArea>
          </Card>

        </div>
    
        

    </div>
  </div>
  )
}

export default BiltongAndBudzGenetics