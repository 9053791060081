import React, {useEffect, useState, Fragment} from "react"
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Product from "../Product/Product";
import './EUShop.css'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import bnbimage from '../../assets/images/bnbgen.png'

//import useGoogleSheets from 'use-google-sheets';
//import Tabletop from "tabletop";

function EUShopIn() {
    //let { name } = useParams();
    const { state } = useLocation();
    const [shopItems, setShopItems] = useState([]);
    const [itemSearch, setItemSearch] = useState('');
    const [shouldShow, setShouldShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [currency, setCurrency] = useState('');
    const name = state.name
    // const { prodctdata, loading, error, refetch } = useGoogleSheets({
    //   apiKey: 'AIzaSyBVzYs4AX69P38xV5FxIzL66pddacqWq3c',
    //   sheetId: "1mHyEDGQsNoju6RrTcsIWzktA9stIhm1Px4o-ab-2guU",
    //   sheetsNames: ['Price List / Order Form'],
    // });

    // setData(JSON.stringify(prodctdata))
 
    // useEffect(() => {
    //   Tabletop.init({
    //     key: "1mHyEDGQsNoju6RrTcsIWzktA9stIhm1Px4o-ab-2guU",
    //     simpleSheet: false
    //   })
    //   .then(function(data, tabletop) { 
    //     console.log(data)
    //   })
    //     .catch((err) => console.warn(err));
    // }, []);
    
  
    useEffect(() => { 
        // console.log("start")
        console.log(state.name)
        console.log(name)

        

        try{
     
            fetch(`https://free.currconv.com/api/v7/convert?q=EUR_ZAR&compact=ultra&apiKey=1965b2a1afabf6871af0`, {
                method:"GET",

                // headers:{
                //   'key':'AIzaSyBVzYs4AX69P38xV5FxIzL66pddacqWq3c'     
                // }, 
              
               
            }).then((putresponsecur)=> putresponsecur.json())
            .then((putresponsecur) => {
                console.log(putresponsecur.EUR_ZAR)
                setCurrency(putresponsecur.EUR_ZAR.toFixed(2))
         
     
                  fetch(`https://sheets.googleapis.com/v4/spreadsheets/1mHyEDGQsNoju6RrTcsIWzktA9stIhm1Px4o-ab-2guU/values/A1:Q11000?key=AIzaSyBVzYs4AX69P38xV5FxIzL66pddacqWq3c`, {
                      method:"GET",
      
                      // headers:{
                      //   'key':'AIzaSyBVzYs4AX69P38xV5FxIzL66pddacqWq3c'     
                      // }, 
                    
                     
                  }).then((putresponse)=> putresponse.json())
                  .then((putresponse) => {
                      console.log(putresponse.values[1][5])
                      const currentItems =putresponse.values
                      const singleProduct = currentItems.filter((item)=>{
                        
                          if ((item.includes('Yes'))){
                            return item
                        }
                          else{
                              return null
                          }
                      })
                      // var columnA = this.props.result.splice(0, halfwayPoint)
                      // var columnB = this.props.result.splice(halfwayPoint)
               
                      
                      setShopItems(singleProduct)
                      setShouldShow(true)
                    
                  }) 
                  .catch((error) => {
                    console.error(error);
                  });
             
              
            })
            .catch((error) => {
              console.error(error);
            });
            
          
            
        }
        catch (e){
            console.error(e);
        }
      }, [])
      const handleAll = () => {
        setItemSearch('')
        
      };
      const handleAutoflower = () => {
        setItemSearch('Auto')
      };
      const handleFem = () => {
        setItemSearch('Feminised')
      };
      
      const handleReg = () => {
        setItemSearch('Regular')
      };
      const handleOpen = (item) => {
        console.log(item.item.commodity_code)
        setSelectedProduct(item.item.commodity_code)
        setOpen(true)
      };
      const handleClose = () => setOpen(false);
  return (
    <div className="eushop">
    <div className="eushop__container">
    <text className="eushop__maintitle">{state.name}</text>
    
    <br/>
    <br/>

    {shouldShow ? 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
              {/* <Button onClick={handleAll}>All</Button> */}
              <Button onClick={handleFem}>Feminised</Button>
              <Button onClick={handleReg}>Regular</Button>
              <Button onClick={handleAutoflower}>Auto</Button>
            
          </ButtonGroup>
      </Box>
      : null}
        <div  >
              
          <Grid container spacing={{ xs: 2, md: 3,}} columns={{ xs: 4, sm: 12, md: 24 }}>
              {shopItems.filter((item)=>{
                    if(itemSearch == ''){
                        // if(item.parentsku == ''){
                        //   return item
                        // }
                        // else{
                        //   return null
                        // }
                       return item
                        
                    }
                    else if (item.includes(itemSearch)){
                      // item.includes(state.name)
                      // if(item.parentsku == ''){
                      //     return item
                        
                      //   }
                      //   else{
                      //     return null
                      //   }
                       return item
                    }
                    else{
                        return null
                    }
              }).map((item, i)=>{
                  return(
                    <Grid item xs={2} sm={4} md={4} key={i}>
                          {/* <div className="product__main">
                            <img src={state.image} alt=''/>
                            <div className="product__info__main">
                              <p className="product__title__main">{item.name}</p>
    
                            </div>
                            <button onClick={()=>handleOpen({item})}>View Product</button>
                          </div> */}
                          <Product 
                              title={item[3]+"-"+item[1]}
                              price={((item[7].slice(1,5))*currency).toFixed(0)}
                              image={bnbimage}
                          />
                          

                    </Grid>
                    
                  
                  )
              })}
          </Grid>
                    
                
               
          
          
            
        </div>

    </div>
</div>
  )
}

export default EUShopIn