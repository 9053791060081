import React, {useEffect, useState} from 'react';
import logo from "../../assets/images/BBnew.jpeg"

// import { db } from '../../firebase';
import { useStateVale } from '../../Provider/StateProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Link, useHistory, useNavigate } from 'react-router-dom'


function Account() {
    const [{basket, user}, dispatch] = useStateVale();
    const [customerInfo, setCustomerInfo] = useState([]);
    const [contactInfo, setContact] = useState([]);
     let navigate = useNavigate(); 
    const userid =user.map(item=>(item.id))

    const axios = require('axios').default;
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [telephone, setTelephone] = useState();
    const [telephoneTwo, setTelephoneTwo] = useState();
    const [company, setCompany] = useState();
    const [website, setWebsite] = useState();
    const [websiteOption, setWebsiteOption] = useState();
    const [address, setAddress] = useState();
    const [addressTwo, setAddressTwo] = useState();
    //const [addressFinal, setAddressFinal] = useState('');
    const [city, setCity] = useState();
    const [province, setProvince] = useState();
    const [country, setCountry] = useState();
    const [postcode, setPostcode] = useState();
    const [vatNo, setVatNo] = useState();
    const [doSell, setDoSell] = useState();
    const [doSellOption, setDoSellOption] = useState();
    const [comments, setComments] = useState();
    
    useEffect(() => { 
        
  
        try{
            fetch(`https://bnb.jnzsoftware.co.za/api/customers/${userid}`, {
                method:"GET",
                headers:{
                     'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                }, 
              
               
            }).then((putresponse)=> putresponse.json())
            .then((putresponse) => {
                console.log(putresponse)
                setCustomerInfo(putresponse)
                setCompany(putresponse.company)
                setTelephoneTwo(putresponse.customfields[1].value)
                setWebsite(putresponse.website)
                setWebsiteOption(putresponse.customfields[0].value)
                setVatNo(putresponse.customfields[5].value)
                setDoSellOption(putresponse.customfields[2].value)
                setDoSell(putresponse.customfields[3].value)
                setComments(putresponse.customfields[4].value)
                setAddress(putresponse.billing_street)
                setCity(putresponse.billing_city)
                setProvince(putresponse.billing_state)
                setCountry(putresponse.billing_country)
                setPostcode(putresponse.billing_zip)
                const companynew = putresponse.company
                fetch(`https://bnb.jnzsoftware.co.za/api/contacts/${userid}`, {
                    method:"GET",
                    headers:{
                        'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                    }, 
                
                
                }).then((putresponsein)=> putresponsein.json())
                .then((putresponseinJson) => {
                    console.log('why')
                    console.log(putresponseinJson[0])
                    // setContact(putresponseinJson[0])
                    setFirstName(putresponseinJson[0].firstname)
                    setLastName(putresponseinJson[0].lastname)
                    setEmail(putresponseinJson[0].email)
                    setTelephone(putresponseinJson[0].phonenumber)
                    // setOrders(putresponseinJson)
                    // const singleProduct = putresponse.filter((item)=>{
                    
                    //     if (item.name.toLowerCase().includes(shopName.toLowerCase())){
                    //         //return item
                    //         if (item.categories.toLowerCase().includes(name.toLowerCase())){
                    //             return item
                    //         }
                    //         else{
                    //             return null
                    //         }
                    //     }
                    //     else{
                    //         return null
                    //     }
                    // })
                    // var columnA = this.props.result.splice(0, halfwayPoint)
                    // var columnB = this.props.result.splice(halfwayPoint)
                    //setShopItems(singleProduct)
                //  setShouldShow(true)
                    //console.log(singleProduct)
                
                
                })
                .catch((error) => {
                console.error(error);
                });
                
                
              
            })
            .catch((error) => {
              console.error(error);
            });
            
          
            
        }
        catch (e){
            console.error(e);
        }

        
      }, [])
  return (
    <div className="signup">
        <Link to="/">
            <img className="login__logo" src={logo} alt=""/>
        </Link>
        <h1>My Account</h1>
        <div className="signup__container">
         
                
                <h5>Contact First Name:*</h5>
                <text>{firstName}</text>
              
                <h5>Contact Last Name: *</h5>
                <text>{lastName}</text>
                <h5>Email: *</h5>
                <text>{email}</text>
                <h5>Contact Telephone: *</h5>
                <text>{telephone}</text>
                <h5>Contact Telephone 2:</h5>
                <text>{telephoneTwo}</text>
                <h5>Company Name:*</h5>
                <text>{company}</text>
                <h5>Website Url:*</h5>
                <text>{website}</text>
                <h5>Address:*</h5>
                <text>{address}</text>
                <h5>City:*</h5>
                <text>{city}</text>
                <h5>Province/Region:*</h5>
                <text>{province}</text>
                <h5>Country:*</h5>
                <text>South Africa</text>
                <h5>Postcode:*</h5>
                <text>{postcode}</text>
                <h5>Would You Like To Be Listed On Our Website</h5>
                <text>{websiteOption}</text>
                <h5>ID/Passport Number</h5>
                <text>{vatNo}</text>
                <h5>Do you supply cannabis seeds already?:</h5>
                <text>{doSellOption}</text>
                <h5>If Yes to above, who do you currently buy from</h5>
                <text>{doSell}</text>
                <h5>Comments</h5>
                {/* <input type="text" value={comments} onChange={e => setComments(e.target.value)}/> */}
                <text>{comments}</text>
            
                
   

          
        </div>
    </div>
  )
}

export default Account