import React, {useEffect, useState} from "react"
import Card from '@mui/material/Card';
import './MassMedicalStrains.css'
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import MassMedicalStrainsImage from '../../../assets/images/US/MassMedicalStrains.jpeg'
import Grid from '@mui/material/Grid';
import Product from "../../Product/Product";
function MassMedicalStrains() {
  const [shopItems, setShopItems] = useState([]);
  const name = 'Mass Medical Strains'
  useEffect(() => { 
    console.log("start")
    try{
        fetch(`https://bnb.jnzsoftware.co.za/api/items`, {
            method:"GET",
            headers:{
                 'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
            }, 
          
           
        }).then((putresponse)=> putresponse.json())
        .then((putresponse) => {
            console.log(putresponse)
            
            const singleProduct = putresponse.filter((item)=>{
              
                if (item.name.toLowerCase().includes(name.toLowerCase())){
                  return item
              }
                else{
                    return null
                }
            })
            // var columnA = this.props.result.splice(0, halfwayPoint)
            // var columnB = this.props.result.splice(halfwayPoint)
            setShopItems(singleProduct)
           
          
        })
        .catch((error) => {
          console.error(error);
        });
        
      
        
    }
    catch (e){
        console.error(e);
    }
  }, [])
  return (
    <div className="massmed">
    <div className="mass__container">
    <text className="usgen__maintitle">{name}</text>
        <div  >
              
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 8, md: 12 }}>
              {shopItems.map((item, i)=>{
                  return(
                    <Grid item xs={2} sm={4} md={4} key={i}>
                          <Product 
                              title={item.name}
                              price={item.price*2}
                              image={MassMedicalStrainsImage}
                          />

                    </Grid>
                  
                  )
              })}
          </Grid>
                    
                
               
          
          
            
        </div>

    </div>
</div>
  )
}

export default MassMedicalStrains