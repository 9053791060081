import React, {useEffect} from "react"


import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Checkout from './components/Checkout/Checkout';
import Orders from './components/Orders/Orders';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup'

import Payment from './components/Payment/Payment';



import { Routes, Route, Link } from "react-router-dom";
import { useStateVale } from "./Provider/StateProvider";
import EUgen from "./components/EUgen/EUgen";
import USgen from "./components/USgen/USgen";
import MassMedicalStrains from "./components/US/MassMedicalStrains/MassMedicalStrains";
import EUShop from "./components/EU/EUShop";
import BiltongAndBudzGenetics from "./components/BiltongAndBudzGenetics/BiltongAndBudzGenetics";
import BiltongAndBudzGrowshop from "./components/BiltongAndBudzGrowshop/BiltongAndBudzGrowshop.js";
import BiltongAndBudzPrepacked from "./components/ BiltongAndBudzPrepacked/BiltongAndBudzPrepacked";
import ContactUs from "./components/Contact/ContactUs";
import EUShopIn from "./components/EU/EUShopIn";
import Growshop from "./components/BiltongAndBudzGrowshop/GrowshopShop/Growshop";
import GeneticsShop from "./components/BiltongAndBudzGenetics/GeneticsShop/GeneticsShop";
import Account from "./components/Account/Account";
import EUgenCat from "./components/EUgen/EUgenCat/EUgenCat";

function App() {
  const [{user, basket}, dispatch] = useStateVale()
  return (
    // <Routes>
    //   <Route path="/" element={<Home />} />
    //   {/* <Route path="about" element={<About />} /> */}
    // </Routes>

    // <BrowserRouter>
      <div >
        <Header/>
        <Routes>
  
            <Route path="/" element={<Home/>}/>
            <Route path="/orders" element={<Orders/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/account" element={<Account/>}/>
            <Route path="/payment" element={<Payment/>}/>
            <Route path="/checkout" element={<Checkout/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/eu-genetics" element={<EUgen/>}/>
            <Route path="/eu-genetics/categories/shop" element={<EUShop/>}/>
            <Route path="/eu-genetics/categories" element={<EUgenCat/>}/>
            <Route path="/eu-genetics/available" element={<EUShopIn/>}/>
            <Route path="/us-genetics" element={<USgen/>}/>
            <Route path="/biltong-and-budz-genetics" element={<BiltongAndBudzGenetics/>}/>
            <Route path="/biltong-and-budz-genetics/shop" element={<GeneticsShop/>}/>
            <Route path="/us-genetics/mass-medical-strains" element={<MassMedicalStrains/>}/>
            <Route path="/biltong-and-budz-growshop" element={<BiltongAndBudzGrowshop/>}/>
            <Route path="/biltong-and-budz-growshop/shop" element={<Growshop/>}/>
            <Route path="/biltong-and-budz-pre-packed" element={<BiltongAndBudzPrepacked/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
        </Routes>
      
      </div>
    // </BrowserRouter>
  );
}

export default App;
