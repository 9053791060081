import React, {useEffect, useState} from "react"
import Card from '@mui/material/Card';
import './EUgen.css'
import seed from '../../assets/images/EU/00seedbank.png'
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import fastbud from '../../assets/images/EU/420FastBuds.png'
import autoseeds from '../../assets/images/EU/AutoSeeds.png'
import barney from '../../assets/images/EU/BarneysFarm.png'
import blimburn from '../../assets/images/EU/Blimburn.png'
import bodhi from '../../assets/images/EU/Bodhiseeds.png'
import CBDCrew from '../../assets/images/EU/CBDCrew.png'
import DNA from '../../assets/images/EU/DNA.png'
import DutchPassion from '../../assets/images/EU/DutchPassion.png'
import ExoticSeeds from '../../assets/images/EU/ExoticSeeds.png'
import ExpertSeeds from '../../assets/images/EU/ExpertSeeds.png'
import GardenofGreen from '../../assets/images/EU/GardenofGreen.png'
import GrandDaddyPurple from '../../assets/images/EU/GrandDaddyPurple.png'
import GreenHouseSeed from '../../assets/images/EU/GreenHouseSeed.png'
import HolySmoke from '../../assets/images/EU/HolySmoke.png'
import Humboldtseed from '../../assets/images/EU/Humboldtseed.png'
import Khalifa from '../../assets/images/EU/Khalifa.png'
import MedicalMarijuana from '../../assets/images/EU/MedicalMarijuana.jpeg'
import Nirvana from '../../assets/images/EU/Nirvana.png'
import Ripper from '../../assets/images/EU/Ripper.png'
import RoyalQueen from '../../assets/images/EU/RoyalQueen.png'
import seedsmanseedbank from '../../assets/images/EU/seedsman-seedbank-2.webp'
import seedstockers from '../../assets/images/EU/seedstockers_300.jpeg'
import sensiseeds from '../../assets/images/EU/sensiseeds.png'
import swetseeds from '../../assets/images/EU/swetseeds.png'
import VitorySeeds from '../../assets/images/EU/VitorySeeds.png'
import bnbimage from '../../assets/images/bnbgen.png'

import { Link, useHistory, useNavigate, useParams } from 'react-router-dom'
function EUgen() {
    let navigate = useNavigate(); 
  return (
    <div className="eugen">
        <div className="eugen__container">
            
        <text className="eugen__maintitle">EU Genetics</text>

            <div className="eugen__row">
                {/* <Card sx={{ width: 150 }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/available`, {state:{name:'In Stock', image:bnbimage}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            
                            image={bnbimage}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                           In Stock
                        </Typography>
                        
                    </CardActionArea>
                </Card> */}
                <Card sx={{ width: 150,marginLeft:3, }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'00 Seeds', image:seed}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            
                            image={seed}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                            00 Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3,  }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'FastBuds', image:fastbud}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={fastbud}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                            420 Fast Buds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3,  }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Auto Seeds', image:autoseeds}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={autoseeds}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Auto Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3,  }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:"Barney's Farm", image:barney}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={barney}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Barney's Farm
                        </Typography>
                        
                    </CardActionArea>
                </Card>

            
          
                <Card sx={{ width: 150,marginLeft:3,  }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Blimburn', image:blimburn}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={blimburn}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Blimburn
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                </div>
                <div className="eugen__row">
                <Card sx={{ width: 150, marginTop:2 }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Bodhi Seeds', image:bodhi}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={bodhi}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Bodhi seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150, marginTop:2,marginLeft:3}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'CBD Crew', image:CBDCrew}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={CBDCrew}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        CBD Crew
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'DNA Genetics', image:DNA}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={DNA}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        DNA Genetis
                        </Typography>
                        
                    </CardActionArea>
                </Card>

            
            
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Dutch Passion', image:DutchPassion}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={DutchPassion}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Dutch Passion
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Exotic Seeds', image:ExoticSeeds}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={ExoticSeeds}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Exotic Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2 }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Expert Seeds', image:ExpertSeeds}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={ExpertSeeds}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Expert Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
  

                </div>
                <div className="eugen__row">
                <Card sx={{ width: 150, marginTop:2 }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Garden of Green', image:GardenofGreen}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={GardenofGreen}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Garden of Green
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150, marginTop:2,marginLeft:3}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Grand Daddy Genetics', image:GrandDaddyPurple}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={GrandDaddyPurple}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Grand Daddy Purple
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2 }}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Greenhouse Seed Co Merch', image:GreenHouseSeed}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={GreenHouseSeed}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Green House Seed
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Holy Smoke', image:HolySmoke}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={HolySmoke}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Holy Smoke
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Humboldt Seed', image:Humboldtseed}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={Humboldtseed}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Humboldt seed
                        </Typography>
                        
                    </CardActionArea>
                </Card>

            
            
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Khalifa Genetics', image:Khalifa}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={Khalifa}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Khalifa Genetics
                        </Typography>
                        
                    </CardActionArea>
                </Card>
       
                </div>
                <div className="eugen__row">
                <Card sx={{ width: 150, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Medical Marijuana Genetics', image:MedicalMarijuana}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={MedicalMarijuana}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Medical Marijuana Genetics
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150, marginTop:2,marginLeft:3}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Nirvana Seeds', image:Nirvana}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={Nirvana}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Nirvana Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Ripper Seeds', image:Ripper}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={Ripper}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Ripper Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>

            
      
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Royal Queen', image:RoyalQueen}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={RoyalQueen}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Royal Queen
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                {/* <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Seed Stockers', image:seedstockers}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={seedstockers}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Seed Stockers
                        </Typography>
                        
                    </CardActionArea>
                </Card> */}
                <Card sx={{ width: 150,marginLeft:3, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Seedsman', image:seedsmanseedbank}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={seedsmanseedbank}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Seedsman
                        </Typography>
                        
                    </CardActionArea>
                </Card>
    

            
            </div>
            
            <div className="eugen__row">
            <Card sx={{ width: 150, marginTop:2,}}>
                <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Sensi Seeds', image:sensiseeds}})}}>
                    <CardMedia
                        component='img'
                        height='140'
                        image={sensiseeds}
                        alt=''

                    />
                    <Typography align='center' gutterBottom variant="h5" component="div">
                    Sensi Seeds
                    </Typography>
                    
                </CardActionArea>
            </Card>
            <Card sx={{ width: 150, marginTop:2,marginLeft:3}}>
                <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Sweet Seeds', image:swetseeds}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={swetseeds}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Sweet Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
                <Card sx={{ width: 150, marginTop:2,}}>
                    <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories`, {state:{name:'Victory Seeds', image:VitorySeeds}})}}>
                        <CardMedia
                            component='img'
                            height='140'
                            image={VitorySeeds}
                            alt=''

                        />
                        <Typography align='center' gutterBottom variant="h5" component="div">
                        Vitory Seeds
                        </Typography>
                        
                    </CardActionArea>
                </Card>
    

            
            </div>
            
            
        </div>
    </div>
  )
}

export default EUgen