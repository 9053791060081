import React, {useEffect, useState} from "react"
import { Routes, Route, useParams, useLocation,useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import '../EUgen.css'
// import bnbimage from '../../../assets/images/bnbgen.png'
function EUgenCat() {
    const { state } = useLocation();
    const [shopItems, setShopItems] = useState([]);
    const [itemSearch, setItemSearch] = useState('');
    const [shouldShow, setShouldShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [open, setOpen] = useState(false);
    let navigate = useNavigate(); 
    const shopName = state.name
    const bnbimage = state.image
    //const shopName ='Biltong And Budz Genetics'
  return (
    <div className="bnbshop">
      <div className="bnbshop__container">
        <text className="bnbshop__maintitle">Biltong And Budz Genetics</text>
        <br/>
        <br/>
        <div className="eugen__row">
          {/* <Card sx={{ width: 150 }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/available`, {state:{name:'In Stock', image:bnbimage}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                      In Stock
                  </Typography>
                  
              </CardActionArea>
          </Card> */}
          <Card sx={{ width: 150,marginLeft:3, }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'Feminised'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Feminised
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'Auto'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Auto
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'Regular'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Regular
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'Fast'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    Fast
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'CBD'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    CBD
                  </Typography>
                  
              </CardActionArea>
          </Card>
          <Card sx={{ width: 150,marginLeft:3,  }}>
              <CardActionArea onClick={()=>{navigate(`/eu-genetics/categories/shop`, {state:{name:shopName, image:bnbimage,categorey:'CBG'}})}}>
                  <CardMedia
                      component='img'
                      height='140'
                      image={bnbimage}
                      alt=''

                  />
                  <Typography align='center' gutterBottom variant="h5" component="div">
                    CBG
                  </Typography>
                  
              </CardActionArea>
          </Card>

        </div>
    
        

    </div>
  </div>
  )
}

export default EUgenCat