import React, {useEffect, useState} from "react"
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import { CardActionArea } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Product from "../Product/Product";
import './BiltongAndBudzPrepacked.css'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import bnbimage from '../../assets/images/bnbgen.png'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function BiltongAndBudzPrepacked () {
    const [shopItems, setShopItems] = useState([]);
    const [itemSearch, setItemSearch] = useState('');
    const [shouldShow, setShouldShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [open, setOpen] = useState(false);
    const shopName ='Biltong And Budz Pre-packed'
    useEffect(() => { 
        console.log("start")
        console.log(shopName)
        try{
            fetch(`https://bnb.jnzsoftware.co.za/api/items`, {
                method:"GET",
                headers:{
                     'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NDU2MDE2NTJ9.Nh2uh5k0wi6VZXwE0n5Mbz_AB7GDagIr7_swkJ4-ta4',
                }, 
              
               
            }).then((putresponse)=> putresponse.json())
            .then((putresponse) => {
                console.log(putresponse)
                
                const singleProduct = putresponse.filter((item)=>{
                  
                    if (item.name.toLowerCase().includes(shopName.toLowerCase())){
                    
                      return item
                  }
                    else{
                        return null
                    }
                })
                // var columnA = this.props.result.splice(0, halfwayPoint)
                // var columnB = this.props.result.splice(halfwayPoint)
                setShopItems(singleProduct)
                console.log(singleProduct)
                // setShouldShow(true)
               
              
            })
            .catch((error) => {
              console.error(error);
            });
            
          
            
        }
        catch (e){
            console.error(e);
        }
      }, [])
      const handleAll = () => {
        setItemSearch('')
        
      };
      const handleAutoflower = () => {
        setItemSearch('Autoflower')
      };
      const handleCBD = () => {
        setItemSearch('CBD')
      };
      const handleCBG = () => {
        setItemSearch('CBG')
      };
      const handleFast = () => {
        setItemSearch('Fast Version')
      };
      const handleFem = () => {
        setItemSearch('Feminized')
      };
      const handleReg = () => {
        setItemSearch('Regular')
      };
      const handleOpen = (item) => {
        console.log(item.item.name)
        const prname = item.item.name.replace('1pk  x20','')
        setSelectedProductName(prname)
        console.log(prname)
        setSelectedProduct(item.item)
        setOpen(true)
      };
      const handleClose = () => setOpen(false);
  return (
    <div className="bnbprepacked">
    <div className="bnbprepacked__container">
    <text className="bnbprepacked__maintitle">Biltong And Budz Pre-packed</text>
    <br/>
    <br/>
  
{/* 
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button onClick={handleAll}>All</Button>
            <Button onClick={handleAutoflower}>Autoflower</Button>
            <Button onClick={handleCBD}>CBD</Button>
            <Button onClick={handleCBG}>CBG</Button>
            <Button onClick={handleFast}>Fast Version</Button>
            <Button onClick={handleFem}>Feminized</Button>
            <Button onClick={handleReg}>Regular</Button>
        </ButtonGroup>
    </Box> */}
        <div  >
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "80%",
              height:'90%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              pt: 2,
              px: 4,
              pb: 3,
            }}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Grid container spacing={{ xs: 2, md: 3,}} columns={{ xs: 4, sm: 12, md: 24 }}>
           
                      <Grid item spacing={{ xs: 2, md: 3,}} container xs={2} sm={4} md={4} direction="row">
                        {shopItems.filter((item)=>{
                                if(item.name.includes(selectedProductName)){
                                  return item       
                                }
                              
                                else{
                                    return null
                                }
                          }).map((item, i)=>{
                              return(
                                <Grid item xs={2} sm={4} md={4} key={i}>
                              
                                      <Product 
                                          title={item.name.replace('Biltong And Budz Pre-packed','')}
                                          price={item.price}
                                          image={bnbimage}
                                      />
                                      

                                </Grid>
                                
                              
                              )
                          })}
                      
                        {/* <Grid item  xs={2} sm={4} md={4} >
                            
                              
                            <Product 
                                title={selectedProductName +" 100 Seeds"}
                                price={selectedProduct.price}
                                image={bnbimage}
                            />
                      
                        

                          </Grid>
                          <Grid item  xs={2} sm={4} md={4} >
                    
                      
             
                          <Product 
                              title={selectedProductName  +" 250 Seeds"}
                              price={10851.00}
                              image={bnbimage}
                          />
                      
                      

                        </Grid>
                        <Grid item  xs={2} sm={4} md={4} >
                          
                    
                          <Product 
                              title={selectedProductName +" 500 Seeds"}
                              price={20942.00}
                              image={bnbimage}
                          />
                      
                      

                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                    
                          <Product 
                              title={selectedProductName +" 1000 Seeds"}
                              price={29510.00}
                              image={bnbimage}
                          />
                      

                        </Grid> */}
                     

                      </Grid>
                      
                    
                    
           
            </Grid>
          
            </Box>
          </Modal>
              
          <Grid container spacing={{ xs: 2, md: 3,}} columns={{ xs: 4, sm: 12, md: 24 }}>
              {shopItems.filter((item)=>{
                    if(itemSearch == ''){
                      if(item.parentsku == null){
                        return item
                      }
                      else{
                        return null
                      }
                      
                      //return item
                    }
                    else if (item.categories.toLowerCase().includes(itemSearch.toLowerCase())){
                      if(item.parentsku == null){
                        return item
                      }
                      else{
                        return null
                      }  
                      //return item
                    }
                    else{
                        return null
                    }
              }).map((item, i)=>{
                  return(
                    <Grid item xs={2} sm={4} md={4} key={i}>
                           <div className="product__main">
                            <img src={bnbimage} alt=''/>
                            <div className="product__info__main">
                              <p className="product__title__main">{item.name.replace('Biltong And Budz Pre-packed','').replace('1pk x20','')}</p>
    
                            </div>
                            <button onClick={()=>handleOpen({item})}>View Product</button>
                          </div>
                          {/* <Product 
                              title={item.name.replace('Biltong And Budz Pre-packed','')}
                              price={item.price}
                              image={bnbimage}
                          /> */}

                    </Grid>
                  
                  )
              })}
          </Grid>
                    
                
               
          
          
            
        </div>

    </div>
</div>
  )
}

export default BiltongAndBudzPrepacked



