import React, { useEffect, useState } from 'react';
import './Payment.css';
import { useStateVale } from '../../Provider/StateProvider';
import CheckoutProduct from '../CheckoutProduct/CheckoutProduct';
import { Link, useNavigate } from 'react-router-dom';
// import {CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CurrencyFormat from "react-currency-format";
import { getBasketTotal } from '../../Reducer/reducer';
//import axios from '../axios/axios';
// import { db } from '../../firebase';
import Cards from 'elt-react-credit-cards';
import "elt-react-credit-cards/es/styles-compiled.css";

function Payment() {
    const [{basket, user}, dispatch] = useStateVale();
    const history = useNavigate();

    // const stripe = useStripe();
    // const elements = useElements();

    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState("");
    const [error, setError] = useState(null);  
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState(true);
    // const [values, setValues] = useState({
    //     cvc: '',
    //     expiry: '',
    //     focus: '',
    //     name: '',
    //     number: '',
    // });
    // handleInputFocus = (e) => {
    //     this.setState({ focus: e.target.name });
    // }
    
    // handleInputChange = (e) => {
    //     const { name, value } = e.target;
        
    //     this.setState({ [name]: value });
    // }

    const [cvc, setCvc] = useState("");
    const [expiry, setExpiry] = useState("");
    //const [focus, setFocus] = useState();
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");

    // useEffect(() => {
    //     // generate the special stripe secret which allows us to charge a customer
    //     const getClientSecret = async () => {
    //         const response = await axios({
    //             method: 'post',
    //             // Stripe expects the total in a currencies subunits
    //             url: `/payments/create?total=${getBasketTotal(basket) * 100}`
    //         });
    //         setClientSecret(response.data.clientSecret)
    //     }

    //     getClientSecret();
    // }, [basket])

    // console.log(basket)
    // console.log("the secret is >>>", clientSecret)
    // console.log("User info >>>>>>>>>>", user)

    const handleSubmit = async (event) => {
        console.log('payment')
        // event.preventDefault();
        // setProcessing(true);

        // const payload = await stripe.confirmCardPayment(clientSecret, {
        //     payment_method: {
        //         card: elements.getElement(CardElement)
        //     },
        // }).then(({ paymentIntent }) => {
        //     db
        //       .collection('users')
        //       .doc(user?.uid)
        //       .collection('orders')
        //       .doc(paymentIntent.id)
        //       .set({
        //         basket: basket,
        //         amount: paymentIntent.amount,
        //         created: paymentIntent.created,
                 
                  
        //       })

            

         
        //     setSucceeded(true);
        //     setError(null)
        //     setProcessing(false)

        //     dispatch({
        //         type: 'EMPTY_BASKET'
        //     })

            
          
        //     history.replace('/orders')           
        // })

    }

    const handleChange = event => {


        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }

    return (
        <div className="payment">
            <div className="payment__container">
                <h1>
                    Checkout (
                        <Link to="/checkout">{basket?.length} items</Link>
                        )
                </h1>


                {/*delivery address */}
                <div className="payment__section">
                    <div className="payment__title">
                        <h3>Delivery Address</h3>
                    </div>
                    <div className="payment__address">
                        <p>{user?.email}</p>
                        <p>18 Oakmount Street</p>
                        <p>Silver Lakes, PTA, SA</p>
                    </div>
                </div>

                {/* Review items */}
                <div className="payment__section">
                    <div className="payment__title">
                        <h3>Review items and delivery</h3>
                    </div>
                    <div className="payment__items">
                        {/* Review items */}
                        {basket.map(item => (
                            <CheckoutProduct
                            id={item.id}
                            title={item.title}
                            image={item.image}
                            price={item.price}
                            rating={item.rating}
                            />
                        ))}
                    </div>
                </div>
                {/* Payment method */}
                <div className="payment__section">
                    <div className="payment__title">
                        <h3>Payment Method</h3>
                    </div>
                    <div className="payment__details">
                        <Cards
                            cvc={cvc}
                            expiry={expiry}
                            // focused={focus}
                            name={name}
                            number={number}
                        
                            />
                            <br />
                            <form onSubmit={handleSubmit}>
                                <input type="text" placeholder="Card Holder Name" focus value={name} onChange={e => setName(e.target.value)}/>
                                <br />
                                <input type="text" placeholder="Card Number" focus value={number} onChange={e => setNumber(e.target.value)}/>
                                <br />
                                <input type="text" placeholder="Card Expiry" focus value={expiry} onChange={e => setExpiry(e.target.value)}/>
                                <br />
                                <input type="text" placeholder="Card CVC" focus value={cvc} onChange={e => setCvc(e.target.value)}/>
                                <button onClick={handleSubmit}>
                                    Pay Now
                                </button>
                            </form>
                        {/* <form>
                            <input
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            // onChange={this.handleInputChange}
                            // onFocus={this.handleInputFocus}
                            />
                         
                        </form> */}
                        {/* Stripe */}
                        
                        {/* <form onSubmit={handleSubmit}>
                            <CardElement onChange={handleChange}/>
                            <div className="payment__priceContainer">
                                
                                <CurrencyFormat
                                    renderText={(value) => (
                                        <h3> Order Total: {value}</h3>
                                    )}
                                    decimalScale={2}
                                    value={getBasketTotal(basket)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                                <button disabled={processing || disabled || succeeded}>
                                    <span>{processing ? <p>Processing</p> : "Buy Now"}</span>
                                </button>
                            </div>

                            {error && <div>{error}</div>}
                        </form> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment